<script setup>
import { routeOrder } from '@/router';

const Page = ref(null);
const { isSwiping, direction } = useSwipe(Page);

const router = useRouter();

const getRouteByIndex = (index) => {
  const normalizedIndex = (index + routeOrder.length) % routeOrder.length;
  return { name: routeOrder[normalizedIndex] };
};

watch(direction, (dir) => {
  if (isSwiping.value) {
    const currentRoute = router.currentRoute.value;
    const currentIndex = routeOrder.indexOf(currentRoute.name);

    if (dir === 'left') {
      router.push(getRouteByIndex(currentIndex + 1));
    } else if (dir === 'right') {
      router.push(getRouteByIndex(currentIndex - 1));
    }
  }
});
</script>

<template>
  <div class="Page" ref="Page">
    <div :class="['Page__Inner']">
      <slot />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
.Page {
  display: grid;
  height: size(screen);
  padding-block: size(20px) size(64px);
  width: size(full);

  &__Title {
    color: color(white);
    font-size: size(32px);
  }

  &__Content {
    @include grid-gap(size(16px));

    color: color(white);
    display: grid;
    line-height: 1.75;

    &--Columns {
      @include grid-columns(2);

      justify-self: center;
    }
  }

  &--HasModal {
    filter: blur(size(4px));
  }

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    max-height: calc(size(450px) + size(280px));
    padding-block: size(none);
    position: relative;
    width: auto;
    top: auto;

    &__Title {
      display: none;
    }
  }

  &__Inner {
    @include align-content(start);
    @include grid-gap(size(40px));

    display: grid;
    overflow: auto;
    padding: size(none) size(20px) size(20px) size(20px);
    transform: translateX(0);

    &--Side {
      @include align-content(start);
      @include flexbox;

      display: grid;
      align-items: start;
      padding-block: size(40px);
      border: 1px solid red;
      transform: translateX(120%);
      transition: transform 0.3s ease;

      @media only screen and (min-width: get-breakpoint(widetablet)) {
        transform: translateX(0);
        position: relative;
        width: auto;
        height: auto;
        background: none;
        z-index: auto;
      }
    }

    &--Visible {
      transform: translateX(0);
      background: color(primary, 50a); // Or any other background color

      @media only screen and (min-width: get-breakpoint(widetablet)) {
        transform: translateX(0);
        position: relative;
        width: auto;
        height: auto;
        background: none;
        z-index: auto;
      }
    }

    &--Hidden {
      transform: translateX(-120%);
      position: absolute;
      background: color(primary, 50a); // Or any other background color

      @media only screen and (min-width: get-breakpoint(widetablet)) {
        transform: translateX(0);
        position: relative;
        width: auto;
        height: auto;
        background: none;
        z-index: auto;
      }
    }
  }
}

.RegistrationButton {
  display: none;

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    display: block;
    justify-self: start;
  }
}
</style>
