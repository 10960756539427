<script setup>
defineProps({
  data: {
    type: Array,
    required: true,
  },
});

const breakpoints = useBreakpoints({
  widetablet: 832,
});

const transitionName = computed(() => route.meta.transition);

let isWidetablet = breakpoints.greater('widetablet');
</script>

<template>
  <nav class="Navigation">
    <!-- <div class="Navigation__Registration">
      <VButton v-if="!isWidetablet" text="Registrácia už len na mieste" color="Danger" />
    </div> -->
    <!-- <VButton
      v-if="!isWidetablet"
      text="Registrácia bude čoskoro spustená"
      color="Secondary"
      class="Navigation__Registration"
      disabled
    /> -->
    <NavigationItem
      v-for="item in data"
      :key="item"
      :text="item.text"
      :icon="item.icon"
      :url="item.url"
    />
  </nav>
</template>

<style lang="scss">
.Navigation {
  @include border-radius(top, $size: size(20px));
  @include flexbox;
  @include justify-content(space-evenly);

  animation: slideUp duration(700) duration(200) forwards;
  background-color: color(primary, 50);
  bottom: 0;
  color: color(primary, 80);
  position: fixed;
  width: size(full);
  // transform: translateY(200%);
  z-index: z-index(9);

  &__Registration {
    position: absolute !important;
    transform: translateY(calc(-100% - size(20px)));
  }

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    @include grid-gap(size(20px));
    @include justify-content(start);

    background-color: transparent;
    bottom: auto;
    padding: size(none) size(none) size(40px) size(40px);
    position: relative;
    width: auto;
  }
}
</style>
