<script setup>
import Default from '@/components/Modals/Default.vue';
import Registration from '@/components/Modals/Registration.vue';

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: null,
  },
});

const selectComponent = (type) => {
  // console.log(type);
  switch (type) {
    case 'registracia':
      return Registration;
    default:
      return Default;
  }
};
</script>

<template>
  <component :is="selectComponent(type)" :type="type" />
</template>

<style scoped lang="scss"></style>
