<script setup>
import { dataAPI } from '@/api';
import { mainNavigation, mobileNavigation } from '@/data/navigation';
import IndexPage from '@/pages/index.vue';

const route = useRoute();
const appStore = useAppStore();
const notificationsStore = useNotificationsStore();
const breakpoints = useBreakpoints({
  widetablet: 832,
});

const hasModal = ref(false);
const modalType = ref(null);

const transitionName = computed(() => route.meta.transition);

let isWidetablet = breakpoints.greater('widetablet');

watch(route, async () => {
  hasModal.value = has(route.query, 'modal');
  modalType.value = route.query?.modal;
});

onMounted(() => {
  dataAPI.csrf();

  setTimeout(() => {
    hasModal.value = has(route.query, 'modal');
    modalType.value = route.query?.modal;
  }, 250);
});
</script>

<template>
  <template v-if="isWidetablet">
    <div class="Web__Inner Web__Inner--Left">
      <IndexPage />
    </div>
    <div class="Web__Inner Web__Inner--Right">
      <Navigation :data="mainNavigation" />
      <router-view v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </template>
  <div class="Web__Inner" v-else>
    <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
    <Navigation :data="mobileNavigation" />
  </div>
  <TheNotification
    :notifications="notificationsStore.notifications"
    :progress-bar-timeout="appStore.appToastTimeout"
  />
  <Teleport to="body">
    <transition name="BackInUp" mode="out-in">
      <Modal v-if="hasModal" :type="modalType" />
    </transition>
  </Teleport>
</template>

<style lang="scss">
/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* BackInUp transition */
.BackInUp-enter-active,
.BackInUp-leave-active {
  transition: all 500ms ease; /* Ensure there's a transition property */
}

.BackInUp-enter-from,
.BackInUp-leave-to {
  transform: translateY(1200px) scale(0.7);

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    transform: scale(0.1);
  }
  opacity: 0;
}

.BackInUp-enter-to,
.BackInUp-leave-from {
  transform: scale(1);
  opacity: 1;
}

/* For forward transition */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease;
}
.slide-left-enter,
.slide-left-leave-to,
.slide-right-enter-from {
  position: absolute;
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-leave,
.slide-left-enter-to {
  transform: translateX(0%);
}

/* For backward transition */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}

.slide-right-enter,
.slide-right-leave-to,
.slide-left-enter-from {
  position: absolute;
  opacity: 0;
  transform: translateX(-100%);
}
.slide-right-leave,
.slide-right-enter-to {
  transform: translateX(0%);
}

html {
  background-color: theme-color(app-background);
}

body {
  background: url(@/assets/images/background_small.webp) no-repeat center center
    fixed;
  @include bg-size(cover);

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    background: url(@/assets/images/background_medium.webp) no-repeat center
      center fixed;
  }

  @media only screen and (min-width: get-breakpoint(desktop)) {
    background: url(@/assets/images/background_large.webp) no-repeat center
      center fixed;
  }
}

.Web {
  @include bg-linear-gradient(
    135deg,
    color(background, 0),
    color(background, 100),
    25%,
    100%
  );
  @include grid-gap(size(40px));

  background-color: color(primary, 20a);
  display: grid;
  height: size(screen);
  overflow: visible;
  overflow: hidden;
  position: relative;
  width: size(full);

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    grid-template-columns: 50% 50%;
    padding: size(100px) size(40px) size(none) size(40px);
  }

  &__Inner {
    &--Left {
      justify-self: end;
    }

    &--Right {
      overflow: hidden;
      position: relative;

      .Page {
        position: absolute;
      }
    }
  }
}
</style>
