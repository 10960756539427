<script setup>
import { informationData } from '@/data/information';
import { mainNavigation } from '@/data/navigation';

definePage({
  name: 'Home',
  meta: {
    layout: 'baseLayout',
    title: 'Home',
    requiresAuth: true,
    scrollToTop: true,
  },
});

const route = useRoute();
const breakpoints = useBreakpoints({
  widetablet: 832,
});

const transitionName = computed(() => route.meta.transition);

let isWidetablet = breakpoints.greater('widetablet');
let showModal = ref(false);
let photoIndex = ref(0);

const showGallery = (index) => {
  showModal.value = true;
  photoIndex.value = index;
};

const galleryPhotos = [
  {
    path: '/photos/2_rocnik/1b4112cd-7630-450b-9d80-afe8051f7b00.jpg',
  },
  {
    path: '/photos/2_rocnik/0817de5b-15aa-48b3-9db2-f3a78385699d.jpg',
  },
  {
    path: '/photos/2_rocnik/1c5697c8-39e8-479f-b1d6-d3487f80fe00.jpg',
  },
  {
    path: '/photos/2_rocnik/1db7de87-8ef2-449e-9576-66b8c3029953.jpg',
  },
  {
    path: '/photos/2_rocnik/223dc0a1-b1e3-42da-acd1-10958001d440.jpg',
  },
  {
    path: '/photos/2_rocnik/44863238-0c4a-490b-8382-b336c44eb4d7.jpg',
  },
  {
    path: '/photos/2_rocnik/548f3afa-58c7-4c61-8dc3-09afe4de966b.jpg',
  },
  {
    path: '/photos/2_rocnik/5926ab0f-c771-48d1-b8fd-c55a24cdde78.jpg',
  },
  {
    path: '/photos/2_rocnik/63b53a54-bd39-43fb-89c5-8c651dcc06f0.jpg',
  },
  {
    path: '/photos/2_rocnik/7f58f838-0b8b-421d-ac99-ddd4333668bc.jpg',
  },
  {
    path: '/photos/2_rocnik/99e5e99f-05a8-4137-98c2-729d2437295d.jpg',
  },
  {
    path: '/photos/2_rocnik/9c9a0fc6-6770-4532-83e4-27ecd9f80440.jpg',
  },
  {
    path: '/photos/2_rocnik/a03fe364-2b24-40e3-a256-f0b1731f8be3.jpg',
  },
  {
    path: '/photos/2_rocnik/a1e1e24e-471c-428d-b97e-396c436f7091.jpg',
  },
  {
    path: '/photos/2_rocnik/c1980327-9e15-4622-878b-55d3447bdfff.jpg',
  },
  {
    path: '/photos/2_rocnik/c6e6b7e4-f79f-4e25-b823-fee6e05e91ad.jpg',
  },
  {
    path: '/photos/2_rocnik/c8f6279b-dbcb-45ea-9820-c478de5bac9b.jpg',
  },
  {
    path: '/photos/2_rocnik/d6e6ea6e-d60a-4c61-a18e-31439bbd9751.jpg',
  },
  {
    path: '/photos/2_rocnik/deb3d1b6-6e53-4103-9d78-06402bade6ba.jpg',
  },
  {
    path: '/photos/2_rocnik/df228cea-f7fe-4e9d-97a7-9f03f7f7acc8.jpg',
  },
  {
    path: '/photos/2_rocnik/fec9de66-ab6e-4455-8a3e-eead8cf9d8cd.jpg',
  },
];
</script>

<template>
  <Page>
    <Header label="2. Ročník" title="Lesňanská Penalta" sub-title="Penalta o kráľa Oravy" />
    <section class="Page__Content">
      <p>2.Ročník sa uskutočnil 27.7.2024 v Oravskej Lesnej na futbalovom štadióne.</p>
      <p>
        Hlavnej súťaže sa zúčastnilo <span class="Highlight Highlight--Secondary">116 hráčov</span>.
      </p>
      <p>
        Kategórie do 12 rokov sa zúčastnilo
        <span class="Highlight Highlight--Secondary">51 detí</span>.
      </p>
      <p><span class="Highlight Highlight--Primary">Ďakujeme a vidíme sa o rok!</span></p>
    </section>
    <section class="Page__Content Page__Content--Columns">
      <PhotosPhoto
        v-for="(photo, index) of [...galleryPhotos.slice(0, 2)]"
        :key="index"
        :path="photo.path"
        :number-of-photos="galleryPhotos.length"
        @click="showGallery(index)"
      />
      <TheGallery
        v-if="showModal"
        :show="showModal"
        :photos="galleryPhotos"
        :photo-index="photoIndex"
        title="2.Ročník"
        @close="showModal = false"
      />
    </section>

    <!-- <Countdown date="2024-07-27 16:00:00.000" />

    <Information :data="informationData" />

    <div class="RegistrationButton">
      <VButton text="Registrácia už len na mieste" color="Danger" />
    </div> -->
  </Page>
</template>

<style lang="scss">
.Highlight {
  @include border-radius($size: size(4px));

  background-color: color(background, 0);
  color: color(primary, 50);
  padding: size(4px);
  font-weight: font-weight(bold);

  &--Primary {
    color: color(primary, 50);
  }

  &--Secondary {
    color: color(secondary, 50);
  }

  &--Danger {
    color: color(red);
  }
}
</style>
