import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createHead } from '@unhead/vue';
import { useLocalStorage } from '@vueuse/core';
import VueGtag from 'vue-gtag';

import App from './App.vue';
import Page from '@/layouts/base.vue';
import { router } from '@/router';
import { notificationsPlugin } from '@/stores/_plugins/notifications';

import '@/assets/styles/app.scss';

/**
 * Clear console after HMR update
 */
if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', () => console.clear());
}

const pinia = createPinia();
const head = createHead();
const app = createApp(App);

pinia.use(notificationsPlugin);
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app
  .use(
    VueGtag,
    {
      config: { id: 'G-LXEE6EZM75' },
    },
    router
  )
  .use(pinia)
  .use(router)
  .use(head)
  .component('Page', Page)
  .mount('#Web');
