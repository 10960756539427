<script setup>
import { useForm } from '@/composables/form/useForm';

const dataStore = useDataStore();

const props = defineProps({
  initialForm: Object,
  generateFormSchema: Function,
});

const emit = defineEmits(['submit']);

const { form, errors, validateField, validateAllFields } = useForm(
  props.initialForm,
  props.generateFormSchema
);

const isProcessing = computed(() => dataStore.isProcessing);
const isLoading = computed(() => dataStore.isLoading);
const response = computed(() => dataStore.response);

const validate = (field) => {
  validateField(field);
  dataStore.clearErrors();
};

const handleSubmit = async () => {
  validateAllFields();

  if (Object.keys(errors).every((key) => errors[key].length === 0)) {
    emit('submit', form);
  }
};
</script>

<template>
  <form @submit.prevent="handleSubmit" class="Form">
    <slot
      :form="form"
      :errors="errors"
      :validate="validate"
      :validate-field="validateField"
      :is-loading="isLoading"
      :is-processing="isProcessing"
      :response="response"
    ></slot>
  </form>
</template>

<style lang="scss">
.Form {
  @include grid-gap(size(24px));

  display: grid;
  position: relative;

  &__Buttons {
    @include flexbox;
    @include grid-gap(size(12px));
    @include justify-content(right);
  }

  &__Errors {
    @include border-radius-box;
    @include flexbox;

    align-items: center;
    background-color: rgba(0, 0, 0, 0.493);
    color: color(red);
    font-size: size(20px);
    height: size(full);
    justify-content: center;
    position: absolute;
    width: size(full);
    font-weight: font-weight(bold);
  }
}
</style>
