<script setup>
const props = defineProps({
  type: {
    type: String,
  },
  id: {
    type: String,
  },
  name: {
    type: String,
  },
  label: {
    type: String,
  },
  icon: {
    type: Object,
  },
  help: {
    type: String,
  },
  maxlength: {
    type: Number,
  },
  tabindex: {
    type: String,
  },
  // 'off', 'on', 'none', 'sentences', 'words', 'characters'
  autocapitalize: {
    type: String,
  },
  errors: {
    type: [Array, String],
  },
  autocomplete: {
    type: String,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  isVertical: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['blur', 'update:checked']);
const model = defineModel();
</script>

<template>
  <div
    :class="[{ 'FormGroup--Vertical': isVertical }, 'FormGroup']"
    v-if="type === 'checkbox'"
  >
    <Checkbox
      :id="id"
      :name="name"
      v-model:checked="model"
      class="FormGroup__Input"
    />
    <InputLabel :for="id" :value="label" class="FormGroup__Label" />

    <InputError :message="errors" />
  </div>
  <div :class="[{ 'FormGroup--Vertical': isVertical }, 'FormGroup']" v-else>
    <InputLabel :for="id" :value="label" class="FormGroup__Label" />

    <TextInput
      v-if="type === 'number'"
      :id="id"
      :name="name"
      :type="type"
      :autocomplete="autocomplete"
      :autocapitalize="autocapitalize"
      :tabindex="tabindex"
      :min="1"
      :max="99"
      v-model="model"
      class="FormGroup__Input"
    />

    <TextInput
      v-else
      :id="id"
      :name="name"
      :type="type"
      :autocomplete="autocomplete"
      :autocapitalize="autocapitalize"
      :tabindex="tabindex"
      :maxlength="maxlength"
      v-model="model"
      class="FormGroup__Input"
    />

    <InputError v-if="errors" :errors="errors" />
  </div>
</template>

<style lang="scss">
.FormGroup {
  @include align-items(center);
  @include flexbox;
  @include grid-gap(size(8px));

  color: color(primary, 10);
  position: relative;

  &__Label {
    cursor: pointer;
    font-weight: font-weight(bold);
  }

  &__Input {
    &[type='text'],
    &[type='email'],
    &[type='password'],
    &[type='number'],
    select {
      @include border-radius-box;

      appearance: none;
      background-color: color(primary, 10);
      border: none;
      color: color(primary, 100);
      font-size: size(16px);
      letter-spacing: 0.05em;
      line-height: size(16px);
      outline: none;
      z-index: z-index(sm);
      width: size(full);
      line-height: size(20px);
      padding: size(16px);

      &[type='password'] {
        letter-spacing: 0.5em;
      }

      &:focus {
        color: color(primary, 80);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: color(primary, 100);
        -webkit-box-shadow: 0 0 3px 0 color(primary, 10) inset;
        box-shadow: 0 0 3px 0 color(primary, 10) inset;
        background: transparent;
      }
    }

    &[type='checkbox'] {
      cursor: pointer;
      position: absolute;
      opacity: 0;

      & + label {
        color: primary(link);
        cursor: pointer;
        position: relative;
      }

      // Box.
      & + label::before {
        content: '';
        border-radius: 0.25rem;
        border: 2px solid color(primary, 60);
        display: inline-block;
        height: 20px;
        margin-right: 0.5rem;
        vertical-align: text-top;
        width: 20px;
      }

      &:checked + label::after {
        content: '';
        border-radius: 0.25rem;
        background: color(primary, 50);
        display: inline-block;
        height: size(12px);
        left: size(4px);
        position: absolute;
        vertical-align: text-top;
        top: size(4px);
        width: size(12px);
      }

      // Box hover
      &:hover + label::before {
        border-color: primary(color);
      }

      // Box focus
      &:focus + label::before {
        border-color: primary(color);
      }

      // Disabled state label.
      &:disabled + label {
        color: color(grey, 20);
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label::before {
        background-color: color(grey, 20);
        border: none;
      }
    }
  }

  &__Icon {
    cursor: pointer;
    left: size(8px);
    position: absolute;
    bottom: size(none);
    top: size(none);
    margin-top: auto;
    margin-bottom: auto;
  }

  &__InputWithImage {
    position: relative;

    .FormGroup__Input {
      padding-left: size(48px);
    }
  }

  &__Errors {
    transition: all 300ms ease;

    &__Item {
      // @include align-items(center);
      @include flexbox;
      @include grid-gap(size(4px));

      &__Icon {
        height: size(16px);
        width: size(16px);
      }

      &__Text {
        font-size: size(14px);
      }
    }

    &-enter-active,
    &-leave-active {
      max-height: 0;
      opacity: 0;
      transform: translateY(calc(-1 * size(12px)));
    }

    // &-enter-from,
    // &-leave-to {
    //   opacity: 0;
    // }

    &-enter-to,
    &-leave-from {
      max-height: 100px; /* Adjust as needed */
      opacity: 1;
    }
  }

  &:has(:focus-visible) {
    color: color(primary, 50);
  }

  &:has(.VErrors) {
    color: color(red);
  }

  &--Vertical {
    @include align-items(start);
    @include flex-direction(column);
  }
}

.fade-enter-active,
.fade-leave-active {
  max-height: 0;
  opacity: 0;
  transform: translateY(calc(-1 * size(12px)));
}

.fade-enter-to,
.fade-leave-from {
  max-height: 100px; /* Adjust as needed */
  opacity: 1;
  transform: translateY(0);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
