export const required =
  (message = 'This field is required') =>
  (value) =>
    value ? null : message;

export const email =
  (message = 'Invalid email') =>
  (value) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : message;

export const minLength = (min, message) => (value) =>
  value && value.length >= min ? null : message || `Field must be at least ${min} characters`;

export const numbers =
  (message = 'Field must contain at least one number') =>
  (value) =>
    /\d/.test(value) ? null : message;

export const letters =
  (message = 'Field must contain at least one letter') =>
  (value) =>
    /[a-zA-Z]/.test(value) ? null : message;

export const mixed =
  (message = 'Field must contain at least one uppercase and one lowercase letter') =>
  (value) =>
    /[a-z]/.test(value) && /[A-Z]/.test(value) ? null : message;

export const symbols =
  (message = 'Field must contain at least one symbol') =>
  (value) =>
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value) ? null : message;

export const alpha =
  (message = 'Field must contain only letters') =>
  (value) =>
    /^[\p{L}\s]+$/u.test(value) ? null : message;

export const alphaAscii =
  (message = 'Field must contain only letters') =>
  (value) =>
    /^[a-zA-Z\s]+$/.test(value) ? null : message;
