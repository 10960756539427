export function notificationsPlugin({ store }) {
  const notificationsStore = useNotificationsStore();

  store.$addNotification = async (
    notification,
    type = 'info',
    timeout = true
  ) => {
    await notificationsStore.add(notification, type, timeout);
  };

  store.$removeNotification = async (notification, type = 'info') => {
    await notificationsStore.remove(notification, type);
  };

  store.$clearNotifications = async () => {
    await notificationsStore.clear();
  };
}
