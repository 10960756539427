export function useForm(initialForm, generateFormSchema) {
  const form = reactive({ ...initialForm });
  const errors = reactive({});

  const formSchema = computed(() => generateFormSchema());

  const validateField = (field) => {
    const rules = formSchema.value[field] || [];
    const fieldErrors = rules
      .map((rule) => rule(form[field]))
      .filter((error) => error);
    if (fieldErrors.length > 0) {
      errors[field] = fieldErrors;
    } else {
      delete errors[field];
    }
  };

  const validateAllFields = () => {
    Object.keys(form).forEach((field) => validateField(field));
  };

  const clearErrors = (field) => {
    errors[field] = [];
  };

  return {
    form,
    errors,
    validateField,
    validateAllFields,
    clearErrors,
  };
}
