<script setup>
import { required, email, alpha } from '@/composables/form/rules';

const dataStore = useDataStore();

const initialForm = reactive({
  name: '',
  surname: '',
  city: '',
  age: '',
  email: '',
});

const generateFormSchema = () => ({
  email: [
    required('Pole E-mail je povinné.'),
    email('Pole E-mail musí byť platná e-mailová adresa.'),
  ],
  name: [required('Pole Meno je povinné.'), alpha('Pole Meno musí obsahovať iba písmená.')],
  surname: [
    required('Pole Priezvisko je povinné.'),
    alpha('Pole Meno musí obsahovať iba písmená.'),
  ],
  city: [required('Pole Mesto / Obec je povinné.')],
  age: [required('Pole Vek je povinné.')],
});

const isLoading = computed(() => dataStore.isLoading);

const handleFormSubmit = async (form) => {
  await dataStore.register(form);
};
</script>

<template>
  <ModalLayout title="Registračný formulár">
    <template #content>
      <!-- <p class="Highlight Highlight--Danger">
        Registrácia je možná už len na mieste od 15:00 do 15:45 v deň súťaže.
      </p> -->
      <VForm
        class="RegistrationForm"
        :initialForm="initialForm"
        :generateFormSchema="generateFormSchema"
        @submit="handleFormSubmit"
      >
        <template
          v-slot="{ form, response, errors, validate, clearErrors, isLoading, isProcessing }"
        >
          <div class="RegistrationForm__Processing" v-if="isProcessing">
            <template v-if="isLoading">
              <VPIcon
                :name="PhSpinnerGap"
                :size="80"
                weight="duotone"
                class="RegistrationForm__Processing__Icon RegistrationForm__Processing__Icon--Spin"
              />
              Spracovávam formulár...
            </template>
            <template v-else-if="response.success">
              <VPIcon
                :name="PhCheckCircle"
                :size="80"
                weight="duotone"
                class="RegistrationForm__Processing__Icon RegistrationForm__Processing__Icon--Success"
              />
              <span v-html="response.message" />
            </template>
            <template v-else>
              <VPIcon
                :name="PhWarningCircle"
                :size="80"
                weight="duotone"
                class="RegistrationForm__Processing__Icon RegistrationForm__Processing__Icon--Error"
              />
              <span v-html="response.message" />
            </template>
          </div>
          <p class="RegistrationForm__Column RegistrationForm__Column--Span6">
            Všetky polička sú povinné.
          </p>
          <FormGroup
            type="text"
            id="password"
            name="password"
            class="RegistrationForm__Column RegistrationForm__Column--HP"
            tabindex="-1"
            autocomplete="off"
          />
          <FormGroup
            type="text"
            v-model="form.name"
            label="Meno"
            id="name"
            name="name"
            :maxlength="25"
            is-required
            is-vertical
            :errors="errors.name || response.errors?.name"
            @input="validate('name')"
            class="RegistrationForm__Column"
          />
          <FormGroup
            type="text"
            v-model="form.surname"
            label="Priezvisko"
            id="surname"
            name="surname"
            :maxlength="25"
            is-required
            is-vertical
            :errors="errors.surname || response.errors?.surname"
            @input="validate('surname')"
            class="RegistrationForm__Column"
          />
          <FormGroup
            type="text"
            v-model="form.city"
            label="Mesto / Obec"
            id="city"
            name="city"
            :maxlength="50"
            is-required
            is-vertical
            autocapitalize="words"
            :errors="errors.city || response.errors?.city"
            @input="validate('city')"
            class="RegistrationForm__Column RegistrationForm__Column--Span4"
          />
          <FormGroup
            type="number"
            v-model="form.age"
            id="age"
            name="age"
            label="Vek"
            :maxlength="2"
            is-required
            is-vertical
            :errors="errors.age || response.errors?.age"
            @input="validate('age')"
            class="RegistrationForm__Column RegistrationForm__Column--Span2"
          />
          <FormGroup
            type="text"
            v-model="form.email"
            id="email"
            name="email"
            label="E-mail"
            :maxlength="50"
            is-required
            is-vertical
            autocapitalize="none"
            :errors="errors.email || response.errors?.email"
            @input="validate('email')"
            class="RegistrationForm__Column RegistrationForm__Column--Span6"
          />
          <div
            class="RegistrationForm__Column RegistrationForm__Column--Small RegistrationForm__Column--Span6"
          >
            Všetky potrebné informácie, súťažiace číslo a spôsob platby vám pošleme na E-mail.
          </div>
          <div
            class="RegistrationForm__Column RegistrationForm__Column--Small RegistrationForm__Column--Span6"
          >
            Kliknutím na "Odoslať" súhlasíte so spracovaním osobných údajov za účelom účasti na
            súťaži. Viac na
            <RouterLink
              :to="{ path: '/oou', query: { modal: 'registracia' } }"
              active-class=""
              exact-active-class=""
            >
              Ochrana osobných údajov
            </RouterLink>
            .
          </div>
          <VButton
            class="RegistrationForm__Column RegistrationForm__Column--Span2"
            text="Odoslať"
            color="Primary"
            :is-loading="isLoading"
            disabled
          />
        </template>
      </VForm>
    </template>
  </ModalLayout>
</template>

<style scoped lang="scss">
.Highlight {
  @include border-radius($size: size(4px));

  background-color: color(background, 0);
  color: color(primary, 50);
  font-size: size(20px);
  line-height: size(32px);
  padding: size(4px);
  font-weight: font-weight(bold);
  text-align: center;

  &--Secondary {
    color: color(secondary, 50);
  }

  &--Danger {
    color: color(red);
  }
}

.RegistrationForm {
  @include grid-columns(6);
  @include grid-gap(size(20px));

  align-content: start;
  align-items: start;
  display: grid;
  padding: size(8px);

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    padding: size(20px);
  }

  &__Processing {
    @include grid-gap(size(20px));

    align-content: center;
    background-color: color(background, 0);
    display: grid;
    font-size: size(16px);
    inset: 0;
    justify-items: center;
    line-height: 1.5;
    position: absolute;
    text-align: center;
    z-index: 9;

    @media only screen and (min-width: get-breakpoint(widetablet)) {
      font-size: size(20px);
    }

    &__Icon {
      &--Spin {
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }

      &--Error {
        fill: color(red);
      }

      &--Success {
        fill: color(green);
      }
    }
  }

  &__Column {
    grid-column: span 3;

    &--Span2 {
      grid-column: span 2;
    }

    &--Span4 {
      grid-column: span 4;
    }

    &--Span6 {
      grid-column: span 6;
    }

    &--Oou {
      line-height: 1.5;
    }

    &--Small {
      font-size: size(14px);
      line-height: 1.5;
    }

    &--HP {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      z-index: -1;
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
