<script setup>
const props = defineProps({
  notifications: {
    type: Array,
    required: false,
    default: null,
  },
  hasCloseButton: {
    type: Boolean,
    required: false,
    default: true,
  },
  hideProgressBar: {
    type: Boolean,
    required: false,
    default: false,
  },
  progressBarTimeout: {
    type: Number,
    required: false,
    default: 5000,
  },
});

let notificationsMessage = ref(props.notifications);

const setIcon = (type) => {
  const icons = {
    info: PhInfo,
    error: PhWarning,
    success: PhCheckCircle,
  };
  return icons[type] || 'PhInfo';
};

const closeNotification = (index) => {
  notificationsMessage.value.splice(index, 1);
};

watch(
  () => props.notifications,
  (newNotifications) => {
    notificationsMessage.value = newNotifications;
  }
);
</script>

<template>
  <div v-if="!isEmpty(notifications)" class="notifications">
    <div
      v-for="(notification, index) in notificationsMessage"
      :key="index"
      :class="[
        'notifications__box',
        `notifications__box--${notification.type}`,
      ]"
    >
      <VPIcon
        :name="setIcon(notification.type)"
        weight="fill"
        class="notifications__box__icon"
      />
      <span class="notifications__box__message">
        {{ notification.notification }}
      </span>
      <TheNotificationProgressBar
        v-if="!hideProgressBar"
        class="notifications__box__progress-bar"
        :progress-bar-timeout="progressBarTimeout"
      />
      <button
        v-if="hasCloseButton"
        class="notifications__box__close-button"
        @click="closeNotification(index)"
      >
        <VPIcon
          :name="PhX"
          weight="bold"
          class="notifications__box__close-button__icon"
        />
      </button>
    </div>
  </div>
</template>

<style lang="scss">
$border-radius: size(12px);

@keyframes scale-x-frames {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}

.notifications {
  @include align-items(end);
  @include flex-direction(column);
  @include flexbox;
  @include grid-gap(size(12px));

  bottom: size(24px);
  left: size(12px);
  position: fixed;
  right: size(12px);
  z-index: z-index(9);

  &__box {
    @include align-items(center);
    @include border-radius($size: $border-radius);
    @include flexbox;
    @include flex-direction(row);
    @include grid-gap(size(12px));

    min-width: size(200px);
    padding: size(16px) size(24px);
    position: relative;

    &--info {
      background: color(primary, 50);
      color: color(secondary, 30);
    }

    &--success {
      background: color(green);
      color: color(green, 10);
    }

    &--error {
      background: color(secondary, 70);
      color: color(secondary, 30);
    }

    &__icon {
      height: size(24px);
      width: size(24px);
    }

    &__progress-bar {
      @include border-radius(top);
      animation: scale-x-frames ease-out forwards;
      animation-duration: 3s;
      background: currentColor;
      bottom: size(none);
      height: size(4px);
      left: $border-radius;
      position: absolute;
      width: calc(size(full) - $border-radius * 2);
      // transform-origin: right;
      transform-origin: left center;
      z-index: z-index(10);
    }

    &__close-button {
      &__icon {
        height: size(12px);
      }
    }
  }
}

@media only screen and (min-width: get-breakpoint(widetablet)) {
  .notifications {
    left: size(auto);
    right: size(24px);
  }
}
</style>
