<script setup>
const model = defineModel({
  required: true,
});

const input = ref(null);

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });

const trimSpaces = (event) => {
  model.value = event.target.value.trimStart();
};
</script>

<template>
  <input v-model="model" ref="input" @input="trimSpaces" />
</template>
